/* responsiveProductListPage */
define(['responsiveFacets', 'responsivePagination', 'responsiveSort', 'app', 'componentHelper', '$window', '$console', 'accessibilityFocusHelper', 'enhancedEcom', 'siteObj'], (facets, pagination, sortSelector, app, helper, $window, $console, accessibilityFocusHelper, enhancedEcom, siteObj) => () => {

  const DEFAULT_NAV_OFFSET = 48;
  const FACET_CATEGORIES_DELIMITER = '|';
  const FACET_DELIMITER = ':';
  const EMPTY_RESULT_CLASS_NAME = '.responsiveEmptySearchResult';

  const _attrs = {
    sectionPath: 'data-section-path',
    isSearch: 'data-is-search',
    show: 'data-show',
    navOffset: 'data-nav-offset',
    focusedFacetCategory: 'data-focused-facet-category',
    showAllFilters: 'data-open-filters',
    horizontalFacets: 'data-horizontal-facets',
    priceFacetCategoryName: 'data-price-facet-category',
    preventPageUpdate: 'data-prevent-update',
    addIndexableFacetsToCanonicalUrl: 'data-update-canonical-url',
    addPageNumberToCanonicalUrl: 'data-pagenumber-in-canonical-url',
    indexableFacets: 'data-indexable-facets',
    indexableFacetsMetatitle: 'data-indexable-facets-metatitle',
    selfReferencingCanonicalTags: 'data-self-referencing-canonical-tags',
    top3ProductIds: 'data-top-3-products'
  };

  const _select = {
    component: '[data-component="responsiveProductListPage"]',
    loaderOverlay: '.responsiveProductListPage_loaderOverlay',
    facets: '.responsiveFacets',
    pagination: '.responsivePaginationPages',
    sortSelector: '.responsiveSort',
    goToRefineSectionButton: '.responsiveProductListPage_goToRefineSectionButton',
    refineTitle: '#responsive-facets-title',
    canonicalTag: '[rel="canonical"]',
    responsiveProductListHeaderTitle: '.responsiveProductListHeader_title',
    enhancedEcommerce: '.hasEnhancedEcommerce',
    listProduct: '.productListProducts_product',
    productBlock: '.productBlock',
    mainContent: '#mainContent',
    stickyWrapper: '.sticky-wrapper',
    navMobile: '.westendHeader'
  };

  const _paths = {
    search: '/search.productlist',
    section: '/section/{}.productlist',
  };

  const _channels = {
    update: 'responsiveProductListPage/update',
    horizontalFacetsError: 'responsiveProducts/horizontalFacets',
    responsiveFacetsError: 'responsiveProducts/responsiveFacets'
  };

  const _meta = {
    followString : 'index, follow',
    nofollowString : 'noindex, nofollow'
  }

  const comp = {
    element: null,
    overlay: null,
    sectionPath: null,
    facets: null,
    query: {
      search: '',
      perPage: undefined,
      pageNumber: undefined,
      facetFilters: undefined,
      sortOrder: undefined,
      productRecommender: undefined,
      sourceProduct: undefined
    },
    // testing
    _channels,
    _attrs,
  };

  /**
   * Init
   *
   * @param {HTMLElement} element
   */
  comp.init = function (element) {
    comp.responsiveListPage = element.querySelector(_select.component);
    comp.element = element;
    comp.overlay = element.querySelector(_select.loaderOverlay);
    comp.goToRefineSectionButton = element.querySelector(_select.goToRefineSectionButton);
    comp.nav = element.querySelector(_select.nav);
    comp.isSearch = element.getAttribute(_attrs.isSearch) === 'true';
    comp.sectionPath = element.getAttribute(_attrs.sectionPath);
    comp.horizontalFacetsEnabled = element.getAttribute(_attrs.horizontalFacets);
    comp.priceFacetCategoryName = element.getAttribute(_attrs.priceFacetCategoryName);
    comp.indexableFacetsEnabled = element.getAttribute(_attrs.indexableFacets);
    comp.indexableFacetsMetatitle = element.getAttribute(_attrs.indexableFacetsMetatitle);
    comp.selfReferencingCanonicalTagsEnabled = element.getAttribute(_attrs.selfReferencingCanonicalTags);
    comp.initialProductListHeaderTitle = comp.element.querySelector(_select.responsiveProductListHeaderTitle);
    comp.canonicalTag = document.querySelector(_select.canonicalTag);
    comp.defaultCanonicalUrl = comp.canonicalTag && comp.canonicalTag.getAttribute('href');
    comp.sendTop3Tracking();
    comp.enhancedEcommerce = element.querySelector(_select.enhancedEcommerce);
    comp.mainContent = element.querySelector(_select.mainContent);
    comp.stickyWrapper = document.querySelector(_select.stickyWrapper);
    comp.responsivePlP = document.querySelector(_select.component);
    comp.navMobile = document.querySelector(_select.navMobile);
    comp.setHeightOfPage();


    if (comp.enhancedEcommerce) {
      const products = document.querySelectorAll(_select.listProduct);
      if (products) {
        enhancedEcom.view_item_list(products);
      }
    }

    if(comp.indexableFacetsEnabled === 'true') {
      comp.useIndexableFacets = true;
    }

    if(comp.selfReferencingCanonicalTagsEnabled === 'true') {
      comp.useSelfReferencingCanonicalTags = true;
    }

    if(comp.useIndexableFacets && comp.urlHasIFPFlag()) {
      comp.updateMetaTag();
    }

    if(siteObj.config.useGa4EnhancedEcom === true) {
      const productBlocks = comp.element.querySelectorAll(_select.productBlock)
      let itemsObjectArray = [];

      productBlocks.forEach(block => {
        const itemsObject = {
          'item_name': block.getAttribute('data-product-name').toString().replace(/&#039;|'/g, ""),
          'item_id': block.getAttribute('data-product-id').toString(),
          'price': block.getAttribute('data-product-price'),
          'item_brand': block.getAttribute('data-product-brand'),
          'item_category': block.getAttribute('data-product-category'),
          'item_list_name': siteObj.sectionName,
          'index': block.getAttribute('data-product-position')
        };

        itemsObjectArray.push(itemsObject);
      })

      app.publish('ga4tracking/record', 'ecom_event', 'view_item_list', siteObj.currencyType, itemsObjectArray);
    }

    comp.initDeps();
    
    app.subscribe(_channels.update, comp.update);
    $window.addEventListener('popstate', comp.onHistoryChange);

    (comp.goToRefineSectionButton !== null) && comp.goToRefineSectionButton.addEventListener('click', comp.skipToRefineSection);

    // if window search not empty, update the canonical url
    if ($window.location.search) {
      comp.updateCanonicalUrl();

      // if(comp.useIndexableFacets && comp.urlHasIFPFlag()) {
      //   comp.updateMetaRobots()
      // }
    }
  };

  /**
   * Initialize dependencies
   *
   * Used in order avoid reproducing the dom required by dependencies in the tests.
   */
  comp.initDeps = function () {
    comp.facets = new facets();
    comp.pagination = new pagination();
    comp.sortSelector = new sortSelector();

    comp.facets.init(comp.element);
    comp.pagination.init(comp.element);
    comp.sortSelector.init(comp.element);
  };

  /**
   *
   * @param {*} pageNumber
   * @returns {string}
   */

  comp.getPageNumber = (pageNumber) => {
    // convert pageNumber to an integer
    pageNumber = parseInt(pageNumber,10);

    if (pageNumber < 2) {
      return '';
    }

    let pageNumberString = `pageNumber=${pageNumber}`;

    return pageNumberString;
  }

  /**
   * Update canonical Url
   *
   * Used to update the canonical url for list page when indexable facets are used
   */
  comp.updateCanonicalUrl = function () {
    const canonicalTag = document.querySelector(_select.canonicalTag);
    const {origin, pathname, search} = $window.location;
    const hasIndexableFacets = comp.getIFPValue();
    const query = comp.parseQuery(search);
    const facetFilters = comp.getCanonicalFacetFilters(query);
    const addIndexableFacetsToCanonicalUrl = comp.element.getAttribute(_attrs.addIndexableFacetsToCanonicalUrl);
    const addPageNumberCanonicalUrl = comp.element.getAttribute(_attrs.addPageNumberToCanonicalUrl);
    const pageType = siteObj.section;

    if (pageType && pageType === 'search') {
      canonicalTag.setAttribute('href', origin + pathname)
      return;
    }

    let pageNumber = '';
    if (query.pageNumber && addPageNumberCanonicalUrl === 'true') {
      pageNumber = comp.getPageNumber(query.pageNumber);
    }
    let ifpString = hasIndexableFacets ? `&IFP=${hasIndexableFacets}` : '';
    let questionMark = (pageNumber.length > 0 || hasIndexableFacets !== 'false') ? '?' : '';

    if(canonicalTag && (hasIndexableFacets === 'true' && addIndexableFacetsToCanonicalUrl === 'true')) {
      pageNumber.length > 0 && (pageNumber = `${pageNumber}&`);
      const canonicalUrl = `${origin}${pathname}${questionMark}${pageNumber}${facetFilters}${ifpString}`;
      canonicalTag.setAttribute('href', canonicalUrl);
    }
    else {
      pageNumber.length > 0 && (pageNumber = `${questionMark}${pageNumber}`);
      canonicalTag && canonicalTag.setAttribute('href', origin + pathname + pageNumber);
    }
  }

  // comp.updateMetaRobots = function () {
  //   const metaRobotsTag = document.getElementsByTagName('meta').robots;
  //   const addIndexableFacetsToCanonicalUrl = comp.element.getAttribute(_attrs.addIndexableFacetsToCanonicalUrl);
  //   if (metaRobotsTag && siteObj.page !== 'search-results' && addIndexableFacetsToCanonicalUrl === 'true' ) { // don't mess with the search results pages & only if the canonical is changing
  //     const hasIndexableFacets = comp.getIFPValue();

  //     if (hasIndexableFacets !== 'true') {
  //       metaRobotsTag.setAttribute('content', _meta.nofollowString);
  //     } else {
  //       metaRobotsTag.setAttribute('content', _meta.followString);
  //     }
  //   }
  // }


  /**
   * Get canonical facet filters
   *
   * Used to get the canonical facet filters from the url
   * passes them to buildFacetFiltersQueryParam
   * returns the facet filters as a string
   *
   * @param {string} search
   * @returns {string}
   *
   **/

  comp.getCanonicalFacetFilters = function (search) {
    let facetFilterParam = comp.getFacetFilterParams(search);

    let facetFilters = [];

    if (facetFilterParam && facetFilterParam.length > 0) {
      facetFilters = comp.buildFacetFiltersQueryParam(facetFilterParam);
      facetFilters.length > 0 && (facetFilters = `facetFilters=${facetFilters}`);
    }

    return facetFilters;
  }

  comp.getFacetFilterParams = function(search) {
    return Object.keys(search)
      .filter(k => search[k] !== undefined && k === 'facetFilters')
      .map(k => search[k]);
  }

  comp.getFacetFilterArray = function(query, indexableFacetKeys) {
    return Array.from(Object.keys(query))
      .filter(key => indexableFacetKeys.includes(key))
      .map(k => `${k}:${query[k]}`)
      .join('|');
  }

  comp.buildFacetFiltersQueryParam = function (facetFilterParam) {
    const indexableFacetKeys = ['en_hbg_baseColour_content', 'en_brand_content', 'en_hbg_sizeFacet_content', 'en_hbg_type_content']; // todo move these to config

    let query = {};
    const queryEntries = facetFilterParam[0]
      .split('|')
      .map(s => s.split(':'));

    for (const [k, v] of queryEntries) {
      query[k] = v;
    }

    return comp.getFacetFilterArray(query, indexableFacetKeys);
  };

  /**
   * Build query from parameters
   */
  comp.build = function () {
    const params = $window.location.search.substr(1).split('&');

    params.forEach(param => {
      const regex = /^([^=]*)=(.*)$/gi;

      if (!param.match(regex)) {
        return;
      }

      // split param into key and variable
      const [, key, value] = regex.exec(param);

      if (comp.query.hasOwnProperty(key)) {
        comp.query[key] = value;
      }
    });
  };

  comp.getIFPValue = () => {
    if (!$window.location.href) return undefined;
    const currentUrl = new URL($window.location.href);
    const queryParams = new URLSearchParams(currentUrl.search);
    const IFP = queryParams.get('IFP') || undefined;

    return IFP;
  };

  /**
   * Update product list filters.
   *
   * @param {{ perPage: number, facetFilters: string[], pageNumber: number, sortBy: string }} obj
   * @param {boolean} replaceHistory
   */
  comp.update = function (obj = {}, hasIndexableFacets, replaceHistory = true) {

    if(hasIndexableFacets  === undefined) {
      hasIndexableFacets = comp.getIFPValue();
    }

    comp.overlay.setAttribute(_attrs.show, 'true');

    comp.build();

    // extend the query with the given object
    // the second parameter is to ensure pageNumber is at 1, unless it was passed in the object
    comp.query = Object.assign(comp.query, {pageNumber: 1}, obj);

    let path;

    if (!comp.isSearch) {
      path = _paths.section.replace('{}', comp.sectionPath);
    } else {
      path = _paths.search;
    }

    if (comp.horizontalFacetsEnabled === 'true') {
      comp.element.setAttribute(_attrs.preventPageUpdate, comp.lastSelectionFromPriceFacet(obj));
    }

    let url = path + '?' + comp.serialise();

    if (comp.element.baseURI.includes('worthByPrice')) {
      url = url + '&worthValue=worthByPrice';
    } else if (comp.element.baseURI.includes('noWorth')) {
      url = url + '&worthValue=noWorth';
    } else if (comp.element.baseURI.includes('productRecommender')) {
      url = url + '&productRecommender=true';
    }

    app.ajax.get({
      url: url,
      success: (res) => comp.onSuccess(res, replaceHistory, hasIndexableFacets),
      error: comp.onError,
    });
  };

  comp.urlHasIFPFlag = () => {
    let query = comp.parseQuery($window.location.search);
    return query['IFP'] === 'true';
  };

  comp.updateMetaTag = function() {
    comp.responsiveProductListHeaderTitle = comp.element.querySelector(_select.responsiveProductListHeaderTitle);
    if(comp.responsiveProductListHeaderTitle) {
      let metaTitle = document.getElementsByTagName('title')[0];
      let trimMetaTitle = metaTitle.innerHTML.trim();
      let updatedMetaTitle = trimMetaTitle.split(' - ');
      if(comp.indexableFacetsMetatitle) {
        let newMetaTitle = trimMetaTitle.split(' | ').slice(1);
        let title = newMetaTitle.join(' | ');
        updatedMetaTitle[0] = comp.responsiveProductListHeaderTitle.innerHTML.trim().concat(' | ' + title);
      } else {
        updatedMetaTitle[0] = comp.responsiveProductListHeaderTitle.innerHTML.trim();
      }
      metaTitle.innerHTML = updatedMetaTitle.join(' - ');
      comp.titlein = comp.initialProductListHeaderTitle.innerHTML.trim();
      comp.content = document.querySelector('meta[name="Description"]').content;
      let trimContent = comp.content.trim();
      let regEx = new RegExp(comp.titlein, 'ig');
      document.querySelector('meta[name="Description"]').content =
        trimContent.replace(regEx, comp.responsiveProductListHeaderTitle.innerHTML).replace(/(\r\n|\n|\r)/gm, '');

      comp.initialProductListHeaderTitle = comp.element.querySelector(_select.responsiveProductListHeaderTitle);
    }
  }

  /**
   * Serialise query for request
   *
   * @returns string
   */
  comp.serialise = function () {
    return Object.keys(comp.query)
      .filter(k => comp.query[k] !== undefined)
      .map(k => `${k}=${comp.query[k]}`)
      .join('&');
  };

  /**
   * Success handler.
   *
   * Navigates to a new page using the current query and reinitialises the component.
   *
   * @param {string} result
   * @param {boolean} replaceHistory
   */
  comp.onSuccess = function (result, replaceHistory, hasIndexableFacets) {
    let focusedFacetCategory = comp.element.getAttribute(_attrs.focusedFacetCategory);
    let showAllHorizontalFilters = comp.element.getAttribute(_attrs.showAllFilters);
    comp.overlay.setAttribute(_attrs.show, 'false');


    if (comp.preventUpdate(result)) {
      app.publish(comp.priceFacetErrorChannel());
      app.publish('tracking/record', 'Empty List', $window.location.href);
    } else {
      // push new list filters to window history
      let query = comp.parseQuery($window.location.search);

      // combine queries
      query = Object.assign(query, comp.query);
      let queryArray = Object.entries(query);
      queryArray = queryArray.filter(([key, value]) => value !== undefined && value !== null && value !== '' && key !== 'IFP');

      let hasFacetFilters = queryArray.filter(([key]) => key === 'facetFilters').length > 0;

      if (comp.useIndexableFacets && hasFacetFilters) {
        // remove IFP from query array
        queryArray.push(['IFP', hasIndexableFacets]);
      }
      let queryAsString = queryArray.map(([key, value]) => `${key}=${value}`).join('&');

      const {pathname, hash} = $window.location;
      let newUrl = `${pathname}?${queryAsString}${hash}`;

      if (replaceHistory) {
        $window.history.pushState({}, $window.document.title, newUrl);
      }

      // replace current element with new one
      const parent = comp.element.parentElement;

      comp.element.outerHTML = result;
      comp.element = parent.querySelector(_select.component);
      comp.overlay = comp.element.querySelector(_select.loaderOverlay);

      comp.facets.init(comp.element, true);
      comp.pagination.init(comp.element);
      comp.sortSelector.init(comp.element);

      app.publish('productQuickbuy/reinitialise');

      helper.reloadAllComponents(comp.element);

      // scroll to top
      const offset = parseInt(comp.element.getAttribute(_attrs.navOffset)) || DEFAULT_NAV_OFFSET;
      const left = $window.pageXOffset || $window.document.documentElement.scrollLeft;
      const top = ($window.pageYOffset || $window.document.documentElement.scrollTop) + comp.element.getBoundingClientRect().top - offset;
      $window.scrollTo(left, Math.max(top, 0));

      if (focusedFacetCategory) {
        comp.element.setAttribute(_attrs.focusedFacetCategory, focusedFacetCategory);
      }

      if (showAllHorizontalFilters) {
        comp.element.setAttribute(_attrs.showAllFilters, showAllHorizontalFilters);
      }

      app.publish('topProductCategory/update');
      app.publish('sponsoredProductsCriteo/reinitialise');
      app.publish('powerReviews/reinitialise');

      if(comp.useIndexableFacets) {
        comp.updateMetaTag();
      }

      if(comp.useIndexableFacets || comp.useSelfReferencingCanonicalTags) {
        comp.updateCanonicalUrl();
        //comp.updateMetaRobots();
      }

      comp.sendTop3Tracking();
      
      comp.goToRefineSectionButton = comp.element.querySelector(_select.goToRefineSectionButton);
      comp.goToRefineSectionButton && comp.goToRefineSectionButton.addEventListener('click', comp.skipToRefineSection);
    }
  };

  /**
   * Error handler.
   *
   * Displays an error message and hides after some time.
   */
  comp.onError = function () {
    $console.error('Cannot reload facets.', arguments);

    comp.overlay.setAttribute(_attrs.show, 'false');
    comp.facets.error();
  };

  /**
   * Parse Query Params.
   *
   * Returns a key value map of the URLs query params
   */
  comp.parseQuery = function (search) {
    const query = {};
    const queryEntries = search
      .substr(1)
      .split('&')
      .map(s => s.split('='));

    for (const [k, v] of queryEntries) {
      query[k] = v;
    }

    return query;

  };

  comp.lastSelectionFromPriceFacet = (obj) => {
    if (obj && obj.facetFilters) {
      let facetFiltersArray = obj.facetFilters.split(FACET_CATEGORIES_DELIMITER);
      return facetFiltersArray[facetFiltersArray.length - 1]
        .split(FACET_DELIMITER)[0] === comp.priceFacetCategoryName;

    }

    return false;
  };

  comp.priceFacetErrorChannel = () => {
    if ($window.screen.width >= 900) {
      return _channels.horizontalFacetsError;
    }

    return _channels.responsiveFacetsError;
  };

  comp.preventUpdate = (result) => {
    if (comp.element.getAttribute(_attrs.preventPageUpdate) === 'true') {
      let wrapperEl = document.createElement('div');
      wrapperEl.innerHTML = result;
      return wrapperEl.querySelector(EMPTY_RESULT_CLASS_NAME) !== null;
    }

    return false;
  };

  /**
   * History change handler.
   *
   * Resets query and updated the page.
   */
  comp.onHistoryChange = function () {
    comp.query = {
      search: undefined,
      perPage: undefined,
      pageNumber: undefined,
      facetFilters: undefined,
      sortOrder: undefined,
      productRecommender: undefined,
      sourceProduct: undefined
    };

    let queryParams = comp.parseQuery($window.location.search);
    comp.update({pageNumber: queryParams['pageNumber'] || 1}, false, false);
  };

  /**
   * Move focus to refine title on click
   *
   * Accessibility: Allow Keyboard/Screen reader user to skip between sections
   */
  comp.skipToRefineSection = function () {
    const refineTitle = document.querySelector(_select.refineTitle);
    refineTitle && accessibilityFocusHelper.focusAndScroll(refineTitle);
  };

  //Send Top 3 Product IDs to GTM
  comp.sendTop3Tracking = () => {
    comp.top3ProductIds = comp.element.getAttribute(_attrs.top3ProductIds);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      listPageRelThree: comp.top3ProductIds
    })
  };

  comp.setHeightOfPage = function () {

    if (!comp.navMobile || !comp.responsivePlP || !comp.stickyWrapper || !comp.mainContent) {
      return;
    }
  
    let mobileDevice = window.matchMedia("(max-width: 900px)");
    let navHeight = comp.navMobile.clientHeight;

    if (!navHeight) {
      return;
    }
  
    if (mobileDevice.matches) {
      comp.responsivePlP.style.display = 'block';
      
      //using main content to accurately set the height of the list
      let mainContentHeight = document.querySelector('#mainContent').clientHeight

      comp.responsivePlP.style.height = mainContentHeight + 'px';
      comp.stickyWrapper.style.top = navHeight + 'px';
      comp.mainContent.style.overflow = 'visible';
    } else {
      comp.responsivePlP.style.display = 'flex';
      comp.responsivePlP.style.height = 'auto';
  
      comp.mainContent.style.overflow = 'hidden';
    }
  };
  
  window.addEventListener("resize", comp.setHeightOfPage);

  return comp;
});
